@mixin StepperRoot($config) {
  .wfp-stepper {
    //-webkit-user-select: none; /* Chrome all / Safari all */
    //-moz-user-select: none; /* Firefox all */
    //-ms-user-select: none; /* IE 10+ */
    //user-select: none;

    .close-button {
      width: 25px;
      background: none;
      border: none;
      outline: none;
      padding: 4px 0 4px 4px;
    }

    .modal-footer {
      display: flex;
      justify-content: space-between;
    }

    &__nav {
      color: #0087cb;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      //background: red;
      align-items: center;
      //-webkit-user-select: none; /* Chrome all / Safari all */
      //-moz-user-select: none; /* Firefox all */
      //-ms-user-select: none; /* IE 10+ */
      //user-select: none;

      &__item {
        //background: pink;
        //max-width: 170px;
        max-width: 240px;
        //width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        padding: 4px;

        &.active {
          .item-id {
            background: #0087cb;
            color: #fff;
          }

          .item-label {
            color: #031c2d;
            font-weight: 500;
          }
        }

        &.disable {
          .item-id {
            border: 1px solid #999999;
            background: #999999;
            color: #fff;
          }

          .item-label {
            color: #999999;
            font-weight: 500;
          }
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        .item-id {
          border: 1px solid #0087cb;
          border-radius: 25px;
          min-width: 25px;
          min-height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
        }

        .item-label {
          text-overflow: ellipsis;

          /* Needed to make it work */
          overflow: hidden;
          //white-space: nowrap;
          //background: #036fdc;
          //display: -webkit-box;
          -webkit-line-clamp: 2;
          //-webkit-box-orient: vertical;
          //overflow: hidden;
        }
      }

      .linker {
        height: 1px;
        background: #d3e1e8;
        margin: 0 5px;
        flex: 1 1 auto;
      }
    }

    &__steps {
      margin-top: 8px;

      .wfp-steps__step {
        //background: red;
      }
    }

    .wfp-assisted-exclusion-step {
      label {
        font-weight: 100;

        &.active {
          font-weight: bold;
        }
      }
    }

    .wfp-confirmation-step {
      label {
        font-weight: 100;
      }
    }

    .wfp-upload-step {
      &__drag-drop-area {
        color: #f8f9f2;
        display: flex;
        align-items: center;
        flex-direction: column;
        border: dashed 3px transparent;
        opacity: 0.7;
        padding: 20px;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        &.drag-active {
          border: dashed 3px #0087cb;
        }

        .drag-drop-icon {
          display: flex;
          max-width: 100px;
          max-height: 100px;
          width: 100%;
          height: 100%;
        }

        //background: red;
      }
    }
  }
}
